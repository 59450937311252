table {
    margin: 15px 0;
    border: 1px solid black;
    width: 120%;
    border: 1px solid rgba(71, 71, 71, 0.164);
  }
  
  td {
    border: 1px solid rgba(71, 71, 71, 0.164);
    overflow: hidden;
    word-wrap: break-word;
  }
  .announcement-table td:nth-child(2),
  .announcement-table td:nth-child(5),
  .announcement-table td:nth-child(6) {
    width: 10%;
  }
  
  .announcement-table tr:nth-child(even) {
    background: rgba(0, 0, 0, 0.041);
  }
  .announcement-table td:nth-child(1),
  .announcement-table td:nth-child(4){
      width: 20%;
  }
  .announcement-table td:nth-child(3) p{
      margin: 15px;
      text-overflow: ellipsis;
      line-height:1.2em;
      height:3.6em;
  }
  .loadmore-btn {
    float: left;
    border-radius: 100px;
    background: linear-gradient(to left, #08d88d, #0eb5a3);
    padding: 5px 20px;
    color: white;
    border: none;
    background-size: contain;
    margin-bottom: 20px;
    width: 300px;
  }
  