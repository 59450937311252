.side-menu-list{
    padding: 0 !important;
    background: #106357;
    border-right:1px solid #3a3a3a5d; 
    overflow-y: scroll !important;
    height: 90vh !important;
    max-width: 100%;
}
.side-menu-list::-webkit-scrollbar-track{
    background: #3a3a3a5d;
}
i{
    font-family: FontAwesome !important;
    font-size: 14px !important;
    margin: auto 5px;
}

#sidebar-list-wrapper ul,#sidebar-list-wrapper li{
    padding: 0 !important;
}
#sidebar-inner-ul{
   background: #283849;
}

.sidebar-parent-li a{
    flex: 3;
    display: block;
    padding: 10px 0px;
    color: #cfd9e4;
    white-space: nowrap;
 }
 .sidebar-parent-li{
     margin:3px auto;
     list-style: none;
 }
 .sidebar-parent-li i{
    flex: 1;
    padding-right: 5px;
    padding-bottom:2px;
    align-self:center; 
    text-align: right; 
}

 .sidebar-parent-li input::placeholder{
    color: #abb4be;

 }

 .sidebar-parent-li a:hover{
     text-decoration: none;
     color: #ffffff;
 }
 .sidebar-parent-li:hover{
     cursor: pointer;
     background: #0e5449;
    }
    .active-main-tab{
     background: #212f3f;
     }
     .non-active-tab{
        background:transparent;
    }
    .group-link{
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #cfd9e4;

    }
    .clear-fix{
        border: 1px solid rgba(0, 0, 0, 0.171);
        margin: 5px 0px;
    }

    
    @media (max-width:575px){
        .side-menu-list{
            min-height: auto;
        }
    
      }


