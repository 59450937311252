#app {
  padding: 40px;
}
.form-container .form-wrapper .instruction-label{
  display: block;
  background: blue;
  text-transform: capitalize;
  margin: 5px 5px 5px 0px;
  white-space: nowrap;
  margin-top: 20px;
}
.form-container .form-wrapper form{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form-wrapper-1{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form-wrapper-2{
  display: flex;
  justify-content: center;
  flex-direction: column;

  /* border: 1px solid red; */
}
div.ReactTags__tags {
  position: relative;
}
/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 20px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px 5px;
  cursor: move;
  border-radius: 5px;
}
a.ReactTags__remove {
  color: rgb(43, 16, 16);
  margin-left: 20px;
  cursor: pointer;
  width: 50px;
  height: 50px;
}

table {
  margin: 15px 0;
  border: 1px solid black;
  width: 150%;
  border: 1px solid rgba(71, 71, 71, 0.164);
}

td {
  border: 1px solid rgba(71, 71, 71, 0.164);
  overflow: hidden;
  word-wrap: break-word;
}

.tds td:nth-child(1),
.tds td:nth-child(4),
.tds td:nth-child(6),
.tds td:nth-child(7) {
  width: 10%;
}
.tds td:nth-child(5) {
  width: 15%;
}
.tds tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.041);
}
.tds td:nth-child(2),
.tds td:nth-child(3) {
  width: 35%;
}
.instructions {
  border: #ddd;
  font-size:   18px;
  border-radius: 10px;
  margin-bottom: 5px;
  max-width: 620px;
  padding: 10px;
  min-height: 120px;
  background: #ffffff90;
}

.form-wrapper .textarea{
  border: 1px solid rgba(128, 128, 128, 0.432);
    color: #414040d2;
    border-radius: 10px;
    background: #ffffff90;
    padding: 20px !important;
    max-width: 620px;
    height: 70px;
    margin-bottom: 5px;
}
