.dashboard-main .widget{
    width: 90%;
    margin-bottom: 20px;
    color: #fff;
    box-sizing: border-box;
    padding: 15px;
    border-radius: 3px;
    overflow: hidden;
    font-size: 16;
    font-weight: bold;
    position: relative;
}

.dashboard-main .row{
    margin-top:10px
}

.dashboard-main .icon-container{
    font-size: 42px;
    height: 56px;
    width: 56px;
    text-align: center;
    line-height: 56px;
    margin-left: 15px;
    color: #fff;
    position: absolute;
    right: 15px;
    top: 15px;
    opacity: .2;
}