
.label{
    display: block;
    text-transform: capitalize;
    margin: 5px 5px 5px 0px;
    white-space: nowrap;
    margin-top: 20px
}
h3{
    white-space: nowrap;
}
.select-wrapper select{
    width: 40vw;
    padding: 10px;
    border: 1px solid rgba(128, 128, 128, 0.432);
    color: rgb(58, 58, 58);
    background:#ffffff90;
    padding-left:  20px !important;
    padding-right:  20px !important;
    border-radius: 100px;
    -webkit-appearance:none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    color: #414040d2;
    background: url("../../assets/down.png") 98% / 4% no-repeat #ffffff90;

}
.Search-select .select-wrapper select{
    width: 16vw;
    padding: 10px;
    border: 1px solid rgba(128, 128, 128, 0.432);
    color: rgb(58, 58, 58);
    background:#ffffff90;
    padding-left:  20px !important;
    padding-right:  20px !important;
    border-radius: 100px;
    -webkit-appearance:none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    color: #414040d2;
    background: url("../../assets/down.png") 96% / 10% no-repeat #ffffff90;

}
.select-wrapper input,.select-wrapper datalist{
    width: 40vw;
    padding: 10px;
    border: 1px solid rgba(128, 128, 128, 0.432);
    background: none;
    color: #414040d2;
    padding-left:  20px !important;
    padding-right:  20px !important;

}

.input-wrapper input{
    width: 40vw;
    padding: 10px;
    border: 1px solid rgba(128, 128, 128, 0.432);
    color: #414040d2;
    border-radius: 100px;
    background:#ffffff90;
    padding-left:  20px !important;
    padding-right:  20px !important;
}
.form-wrapper textarea{
    border: 1px solid rgba(128, 128, 128, 0.432);
    color: #414040d2;
    border-radius: 50px;
    background:#ffffff90;
    padding:  20px !important;
    width: 40vw;
}
.form-wrapper textarea:focus{
   outline: none !important;
}
.check-wrapper .label{
    display: inline-block;
}
.check-wrapper input[type=checkbox]{
    vertical-align: middle;
}
input:focus,select:focus{
    outline: none;
}
.error{
    color: rgb(139, 0, 0);
}
.my-btn-2:focus,.my-btn-2:focus,.my-btn-3:focus{
    outline: none !important;
}
.my-btn-1{
    padding: 5px 0px;
    width: 20vw;
    margin: 5px 0px;
    color: #414040d2;
    border: 1px solid rgba(128, 128, 128, 0.432);
    border-radius: 100px;
    
}
.my-btn-2{
    padding: 5px 0px;
    width: 40vw;
    margin: 25px auto;
    margin-left: 10px;
    color: #414040d2;
    background:#ffffff97;
    border: 1px solid rgba(128, 128, 128, 0.432);
    border-radius: 100px;
}
.my-btn-3{
    border-radius: 100px;
    background:#ffffff97; 
    color: #414040d2; 
    padding: 10px 30px;  
    border: 1px solid rgba(128, 128, 128, 0.432);
}
.keywords{
    border-radius: 100px;
    background:#ffffff97; 
    color: #414040d2; 
    padding: 10px 30px;  
    border: 1px solid rgba(128, 128, 128, 0.432); 
}
.status{
    font-size: 30px;
    margin: 10px 0px;
}
.hideErr{
    height: 0;
    transition: all 5s;
}
.showErr{
    height: auto;
    transition: all 5s;
}

@media (max-width:575px){
    .select-wrapper select{
        width: 80vw;
        height:40px;
    }
    .input-wrapper input{
        width: 80vw;
        height:40px;
    }
    .my-btn-1{
        width: 80vw;
    }
    .my-btn-2{
        width: 80vw;
    }
}