.main-box-container{
    display: flex;
    flex-direction: column;
    margin: 20px auto;
}
.note-editor{
    background:white

}
.note-editing-area textarea {
    background: #000 !important;
}
.collection-of-lesson{
    overflow: auto;
    max-height: 500px;
}

.modal-title{
    position: absolute;
    top: 14px;
}
.legend-input-container-3{
    display: flex;
    flex-direction:column;     
}
.btn-container{
    margin: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.innerbtngroup{
    width: 80%;
    display: flex;
    justify-content: center;
}
.innerbtngroup .my-btn-3{
    margin: 5px;
}


.main-box-container h3{
    text-decoration: underline;
    text-decoration-color: #ada7a74d;
}
.legends-container{
    margin: 20px auto;
    align-self: stretch;
    width: 100%;
}
.legends-container fieldset{
    border: 1px solid rgba(96, 96, 96, 0.468);
    padding: 20px;
    border-radius: 3px;
    width: 100%;
}
legend{
    width:auto !important;
}
.legends-container:nth-child(1){
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
}
.legends-container{
    flex:5;
}
.publishBtn{
    padding: 5px 40px !important;
    align-self: center;
}
.legends-container input{
    width: 95% !important;
    height: 42px !important;
}
.legends-container input[type="checkbox"]{
    width: 20px !important;
    height: 42px !important;
}
.legend-input-container{
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
}


.main-box-container textarea{
    border: 1px solid rgba(128, 128, 128, 0.432);
    color: #414040d2;
    border-radius: 3px;
    background: #ffffff90;
    padding: 20px !important;
    width: 100%;
}
.main-box-container textarea:focus{
    outline: none !important;
}

.innerbtngroup .my-btn-3 {
    white-space: nowrap;
}
.lesson-list{
    display: block;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background:#ffffff45;
    border: 1px solid rgba(128, 128, 128, 0.432);
    margin: 10px;
}

.lesson-list .lesson-li{
    display: flex;
    border: none;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    background:#ffffff45;
    padding: 10px 40px 5px 10px;
    cursor: pointer;
    white-space: nowrap;
}
.lecture-title{
    color: solid #414040d2 
    ;
}
.lesson-li .my-btn-3{
    padding: 5px 15px;
    margin: 5px 10px 0px 0px;
    width: 100px;
    display: inline-block;
}
.lesson-list .keywords{
    justify-content: space-between;
    background:#ffffff45;
    border: none;
    border-radius: 5px;
    padding: 10px 40px 5px 10px;
    cursor: pointer;
}
.zIndexBtn{
    z-index:11111;

}
.show-lesson-title{
    margin: 10px 0px;
    font-size: 20px;
}
.show-lesson-des{
    border: 1px solid rgba(128, 128, 128, 0.432);
    color: #414040d2;
    height: 100px;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    background:#ffffff45;
    overflow-y:auto; 
}
.show-lesson-video{
    height: 50vh;
    background:#ffffff45;
    border: 1px solid rgba(128, 128, 128, 0.432);
    display: flex;
    border-radius: 5px;
    flex-direction:column; 
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
    padding: 10px;
}
.show-lesson-video iframe{
    width: 99.5%;
    height: 99%;
    margin: 2px 0px;
    border-radius: 50px !important;

}    
.legend-upper-group{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.legend-upper-group .my-btn-3{
    padding: 5px 15px;
    width: 150px;
}
.btn-center{
    text-align: center
}