@import url('https://fonts.googleapis.com/css?family=Montserrat');

*{
  font-family: 'Montserrat', sans-serif !important;
}


.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.group-admin{
  display: flex;
  
}


.admit-card-image{
  width: 150px ;
  height: 150px;
  background-position: 50% 50%;
  background-size: contain;
  background-color: black;
  background-repeat: no-repeat;
  margin-top:5px;
}


/* Loader */
.LoaderContainer{
  width:100%;
  height:100vh;
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index:1111111;
  background: rgba(255, 241, 241, 0.534)
}
.loader{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%)
}


.lds-facebook {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background: #017969;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 6px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 26px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 45px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 6px;
    height: 51px;
  }
  50%, 100% {
    top: 19px;
    height: 26px;
  }
}



.my-table{
  font-size: 14px;
}
.table-responsive{
  height: 78vh;
}

.table tr{
  cursor: pointer;

}

.table tr:nth-child(even){
  background: rgba(0, 0, 0, 0.041);
}
.table tr:nth-child(odd){
}
.table{
  empty-cells: show;
}


