.student-form-wrapper,.form-wrapper{
    display: flex;
    justify-content: center;
}
.admit-card-image-con{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.student-form-wrapper form,.form-wrapper form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    
}
.admit-card-image{
    border: 5px solid white;
    border-radius:10px; 
}
.student-form-wrapper h3,.form-wrapper h3{
    margin:20px auto;
    font-size: 40px;
}
.form-wrapper-2{
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* border: 1px solid red; */
}
.radio-wrapper-student-form{
    width: 40% ;
    justify-content: space-between;
}
@media only screen and (max-width: 1500px){
    .radio-wrapper-student-form{
        width: 80% !important;
    }
}