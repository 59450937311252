.editorWrapper { 
    margin:  15px auto;
    background-color: white;
    border-radius: 25px;
    width: 500px;
    padding: 10px;
}

.toolbarWrapper { 
    border-radius: 25px;
}


.emailWrapper { 
    height: 300px;
    overflow: hidden;
}

table {
    margin: 15px 0;
    border: 1px solid black;
    width: 100%;
    border: 1px solid rgba(71, 71, 71, 0.164);
  }
  
  td {
    border: 1px solid rgba(71, 71, 71, 0.164);
    text-align: center;
  }

 .name{
     text-align: left
 }
  th{
    text-align: center;

  }

.status-btn{
    position: absolute;
    margin-top: none !important;
    top:25px;
    right: 10px;
    padding: 10px 0px;
    width: 20vw;
    color: #414040d2;
    background:#ffffff97;
    border: 1px solid rgba(128, 128, 128, 0.432);
    border-radius: 100px;
}
.status-btn:focus{
    outline: none !important
}