
.voucher-table-div{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.voucher-table{
    width: 80%;
}
.model-table-div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.model-table{
    width:80%;
    border: none;
}
.model-table tr th{
    padding: 5px;
    text-align: center;
    border:none;

}
.model-table tr td{
    padding: 5px;
    text-align: center;
    border:none;
  
}
.voucher-table tr th{
    padding: 5px;
    text-align: justify;
    border: 1px solid rgba(71, 71, 71, 0.164);
    width: 50%;
}
.voucher-table tr td{
    padding: 5px;
    text-align: justify;
}
.voucher-btn-1{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    width: 30vw;
    margin-bottom: 50px;
    background: linear-gradient(to left, #08d88d, #0eb5a3);
    color: #ffff;
    border: 1px solid ;
    border-radius: 100px;
}

.voucher-text-2{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
    width: 40vw;
    margin: 2px 0px;
    color:#106357;
 
}
.voucher-text-3{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
    width: 40vw;
    margin: 2px 0px;
    color: rgb(139, 0, 0)
}
.voucher-btn-4{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    width: 40vw;
    margin: 5px 0px;
    margin-bottom: 50px;
    background:#ffffff97; 
    color: #414040d2; 
    border: 1px solid rgba(128, 128, 128, 0.432);
    border-radius: 100px;
}
.model-heading{
    display: flex;
    justify-content: center;
    margin :35px 0;

}
.model-heading h4{
    padding: 2px;
    color: #106357;
    }
.model-btns{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.model-btn-1{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0px;
    width: 200px;
    margin: 5px 0px;
    margin-bottom: 50px;
    background: #106357;
    color: #ffff;
    border: 1px solid rgba(128, 128, 128, 0.432);
    border-radius: 100px;
}
.model-btn-2{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0px;
    width: 200px;
    margin: 5px 0px;
    margin-bottom: 50px;
    background: rgb(139, 0, 0);
    color: #ffff;
    border: 1px solid ;
    border-radius: 100px;
}