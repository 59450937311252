html{
  height:100vh;
  width:100vw;
  padding: 0 !important;
  margin: 0 !important;
}
body{
  height:100%;
  width:100%;
  padding: 0 !important;
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to bottom, #dfedf0, #dadfe6) !important;
}
body {
  overflow:hidden !important; 
}
*{
  font-family: 'Roboto', sans-serif !important;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3a3a3a2a; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(52, 133, 124); 
}
.form-wrapper-admin{
  border: 1px solid red;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-content: center;
}

#root{
  max-height:100%;
  min-height:100%; 
}
.main-page{
  display: flex;
  flex-direction: column;
  height: 100vh !important;
  margin: 0 !important;
}

.my-nav{
  background: rgb(240, 240, 240) !important;
  width:100vw;
  flex: 0.8;
}

.main-route-row{
  flex:8;
  margin: 0 !important;
  height: 100vh;
}
.main-route-tab{
  height: 90vh !important;
  overflow-y: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.radio-wrapper-2 label{
  margin-left:20px; 
}
.radio-wrapper-2 input{
  vertical-align: middle;
}
.radio-wrapper-2 .label{
  display: inline !important;

}
.success{
  color: green;
  font-weight: bold;
}

.routejs-row{
  margin: 0 !important;
}
.form-wrapper-7{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.my-table td,.my-table th{
  white-space: nowrap;
  border: 1px solid rgba(71, 71, 71, 0.164);
  text-align: center;
}

.radio-wrapper input[type=radio]  ,.radio-wrapper .label{
  display: inline-block;
  vertical-align: middle;
  padding: 0px 10px;
}
.table-responsive{
  height: auto !important;
}
.campusDetails{
  margin: 20px 0px;
  align-self: stretch;
  padding-left:20px;
}

.campusDetails h3{
  margin: 10px auto;
  font-size: 16px;
  font-weight: bold;
}

.campusDetails span{
  margin:10px;
  font-size: 14px;
}

.flexer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.test-border {
  border: solid 1px red !important;
}

.flexer-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}